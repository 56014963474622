<template>
	<v-skeleton-loader type="image,image,image"></v-skeleton-loader>
</template>

<script>
export default {
	created() {
		this.$store.dispatch('gmb/getOrgData', { payload: this.$route.query })
	},
}
</script>
